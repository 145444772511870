/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss"
import "katex/dist/katex.min.css"

// import "prismjs/themes/prism-solarizedlight.css"
import "./src/styles/code-theme.scss"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

export { default as wrapRootElement } from './src/state/ReduxWrapper';

export const onRouteUpdate = ({location, prevLocation}) => {
    console.log('new pathname', location.pathname)
    console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}