// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-experience-js": () => import("./../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-scrapbook-germany-2020-js": () => import("./../src/pages/scrapbook/germany-2020.js" /* webpackChunkName: "component---src-pages-scrapbook-germany-2020-js" */),
  "component---src-templates-posts-page-layout-jsx": () => import("./../src/templates/posts-page-layout.jsx" /* webpackChunkName: "component---src-templates-posts-page-layout-jsx" */)
}

