const initialState = {
    isHomeSelected: false,
    isExperienceSelected: false,
    isProjectsSelected: false,
    isErrorSelected: false,
    isBlogSelected: false,
    isToggleReloadOn: false
  };

// ACTIONS
const TOGGLE_HOME = 'TOGGLE_HOME';
const TOGGLE_EXPERIENCE = 'TOGGLE_EXPERIENCE';
const TOGGLE_PROJECTS = 'TOGGLE_PROJECTS';
const TOGGLE_ERROR = 'TOGGLE_ERROR';
const TOGGLE_BLOG = 'TOGGLE_BLOG';
const TOGGLE_RELOAD_ON_LINK = 'TOGGLE_RELOAD_ON_LINK';

export const toggleHome = isHomeSelected => ({
  type: TOGGLE_HOME, isHomeSelected
});

export const toggleExperience = isExperienceSelected => ({
    type: TOGGLE_EXPERIENCE, isExperienceSelected
});

export const toggleProjects = isProjectsSelected => ({
    type: TOGGLE_PROJECTS, isProjectsSelected
});

export const toggleError = isErrorSelected => ({
    type: TOGGLE_ERROR, isErrorSelected
});

export const toggleBlog = isBlogSelected => ({
    type: TOGGLE_BLOG, isBlogSelected
});

export const toggleReload = isToggleReloadOn => ({
    type: TOGGLE_RELOAD_ON_LINK, isToggleReloadOn
});

// Reducer
export default (state = initialState, action) => {

    switch (action.type) {
        case TOGGLE_HOME:
            return { ...state, isHomeSelected: action.isHomeSelected };
        case TOGGLE_EXPERIENCE:
            return { ...state, isExperienceSelected: action.isExperienceSelected };
        case TOGGLE_PROJECTS:
            return { ...state, isProjectsSelected: action.isProjectsSelected };
        case TOGGLE_ERROR:
            return { ...state, isErrorSelected: action.isErrorSelected };
        case TOGGLE_BLOG:
            return { ...state, isBlogSelected: action.isBlogSelected };
        case TOGGLE_RELOAD_ON_LINK:
            return { ...state, isToggleReloadOn: action.isToggleReloadOn };
        default:
            return state;
    }
};